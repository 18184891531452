/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


// Inject YouTube API script
/*var tag = document.createElement('script');
tag.src = "//www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;

function onYouTubePlayerAPIReady() {
  // create the global player from the specific iframe (#video)
  player = new YT.Player('splash-video');
}*/

(function($) {
	
	/* jQuery plugin to enable swipe gestures on Bootstrap 3 carousels.
 * Examples and documentation: https://github.com/maaaaark/bcSwipe*/
	$.fn.bcSwipe = function(settings) {
    var config = { threshold: 50 };
    if (settings) {
      $.extend(config, settings);
    }

    this.each(function() {
      var stillMoving = false;
      var start;

      if ('ontouchstart' in document.documentElement) {
        this.addEventListener('touchstart', onTouchStart, false);
      }

      function onTouchStart(e) {
        if (e.touches.length == 1) {
          start = e.touches[0].pageX;
          stillMoving = true;
          this.addEventListener('touchmove', onTouchMove, false);
        }
      }

      function onTouchMove(e) {
        if (stillMoving) {
          var x = e.touches[0].pageX;
          var difference = start - x;
          if (Math.abs(difference) >= config.threshold) {
            cancelTouch();
            if (difference > 0) {
              $(this).carousel('next');
            }
            else {
              $(this).carousel('prev');
            }
          }
        }
      }

      function cancelTouch() {
        this.removeEventListener('touchmove', onTouchMove);
        start = null;
        stillMoving = false;
      }
    });

return this;
	 };	
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //
        var offset;
        offset = 60;
        if (navigator.userAgent.match(/iPad/i) != null) {
          offset = 100;
        }

/*
        if ($('.content-blocks.row').find('.contactus-body-content').length) {
          $(function() {
            $('.content-blocks.row').toggleClass('special');
          });
        };

        if ($('.content-blocks.row').find('#individual-header-text').length) {
          $(function() {
            $('#individual-header-text').toggleClass('special');
          });
        };

  */     

        $(document).on("scroll", function() {
          if ($(document).scrollTop() > 100) {
            $("header.banner").addClass("navbar-shrink");
			  $(".navbar-collapse").addClass("navbar-menu-shrink");
			  $(".brand").addClass("brand-shrink");
			  $(".navbar-toggle").addClass("navbar-toggle-shrink");
			  $(".header-cta").addClass("shrink");
			  $("p.menu-label").addClass("menu-label-shrink");
          } else {
            $("header.banner").removeClass("navbar-shrink");
			  $(".navbar-collapse").removeClass("navbar-menu-shrink");
			  $(".brand").removeClass("brand-shrink");
			  $(".navbar-toggle").removeClass("navbar-toggle-shrink");
			  $(".header-cta").removeClass("shrink");
			  $("p.menu-label").removeClass("menu-label-shrink");
          }
        });


		  //Enable swiping...
			$('.carousel').bcSwipe({ threshold: 50 });

		
		  
        $('a[href*="#"]:not([href="#"],[data-slide="prev"],[data-slide="next"],[data-toggle="collapse"],[data-toggle="tab"],[href="#tab"])').click(function() {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = (target.length ? target : $('[name=' + this.hash.slice(1) + ']'));
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - offset
              }, 1000);
              $('.navbar-collapse.in').removeClass("in");
              return false;
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
		  





      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        //
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
